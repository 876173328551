import axios from 'axios';
import config from './Config';
import store from '../store/index';

export default class Apicall {
  return = {
    status: 'OK',
    data: null,
    msg: null,
  };

  header = {};

  getHeaders() {
    const thetoken = store.state.token;
    this.header = {
      headers: {
        'Accept-Language': store.state.currentlang,
        Accept: 'application/json',
        Authorization: `Bearer ${thetoken}`,
      },
    };
    return this.header;
  }

  async call(method: string, path: string, postdata?: any) {
    const self = this;
    const domain = config.basedomain;
    if (method === 'GET') {
      return axios.get(`${domain}${path}`, self.getHeaders()).then((response: any) => {
        self.return.data = response.data.data;
        self.return.msg = response.data.msg;
        self.return.status = response.data.status;
        return self.return;
      }).catch((response: any) => {
        self.return.status = 'ERROR';
        self.return.msg = response.response.msg;
        if (response.response.data.msg) {
          self.return.msg = response.response.data.msg;
        }
        return self.return;
      });
    }

    return axios.post(`${domain}${path}`, postdata, self.getHeaders()).then((response: any) => {
      self.return.data = response.data.data;
      self.return.msg = response.data.msg;
      self.return.status = response.data.status;
      return self.return;
    }).catch((response: any) => {
      self.return.status = 'ERROR';
      self.return.msg = response.response.msg;
      if (response.response.data.msg) {
        self.return.msg = response.response.data.msg;
      }
      return self.return;
    });
  }
}
