<template>
  <div>
    <mdb-dropdown class="nav-item notifications-nav" tag="li">
      <mdb-dropdown-toggle slot="toggle" tag="li" outline="none" class="btn-transparent">
        <mdb-badge color="red" v-if="notifications.length">{{getNbNotificationsFriendly()}}</mdb-badge> <mdb-icon icon="bell" /> <span class="d-none d-md-inline-block">Notifications</span>
      </mdb-dropdown-toggle>
      <mdb-dropdown-menu>
        <mdb-dropdown-item v-for="(notif, index) in notifications.slice(0,10)" v-bind:key="index" v-on:click="currentnotif=notif;">
          <mdb-icon :icon="getIconForCat(notif.notificationkey)" class="mr-2" aria-hidden="true" />
          <span>{{notif.title}}</span>
          <span class="float-right"><mdb-icon icon="clock-o" arfaa-hidden="true"/> {{getTimeAgo(notif.created_at)}}</span>
        </mdb-dropdown-item>
        <mdb-dropdown-item v-if="!notifications.length">
          <span>No new notification</span>
        </mdb-dropdown-item>
        <hr v-if="notifications.length > 10" />
        <mdb-dropdown-item v-if="notifications.length > 10" v-on:click="goToNotificationCenter" class="text-center">
          <h6 class="font-weight-bolder">Notification center</h6>
        </mdb-dropdown-item>
      </mdb-dropdown-menu>
    </mdb-dropdown>
    <mdb-modal v-if="currentnotif" :show="currentnotif" info>
      <mdb-modal-header :close="false">{{currentnotif.title}}</mdb-modal-header>
      <mdb-modal-body>
        {{currentnotif.message}}
        <div v-if="currentnotif.url" class="w-100 text-center">
          <hr />
          <mdb-btn tag="a" target="_blank" :href="currentnotif.url" style="padding:.84rem 2.14rem;" color="secondary" size="sm">{{currentnotif.urltitle}}</mdb-btn>
        </div>
      </mdb-modal-body>
      <mdb-modal-footer><mdb-btn outline="elegant" style="padding:.84rem 2.14rem;" v-on:click="closemodal()">Close</mdb-btn></mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbDropdown,
  mdbDropdownToggle,
  mdbBadge,
  mdbIcon,
  mdbDropdownMenu,
  mdbDropdownItem,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';

export default {
  name: 'notificationheader',
  data() {
    return {
      notifications: [],
      currentnotif: null,
      iconsassoc: [
        {
          cat: 'PAXNOBIRTHDATE',
          icon: 'birthday-cake',
        },
        {
          cat: 'HOLASUNINVALIDFILENAME',
          icon: 'file-excel',
        },
        {
          cat: 'OVERBOOKING',
          icon: 'infinity',
        },
        {
          cat: 'CREWCHANGEERROR',
          icon: 'users',
        },
        {
          cat: 'AIRCRAFTTAKEOFF',
          icon: 'plane-departure',
        },
        {
          cat: 'AIRCRAFTLANDING',
          icon: 'plane-arrival',
        },
      ],
    };
  },
  components: {
    mdbDropdown,
    mdbDropdownToggle,
    mdbBadge,
    mdbIcon,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
  },
  mounted() {
    this.loadNotifications();
  },
  methods: {
    loadNotifications() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/notification/list').then((response) => {
        self.notifications = response.data;
      });
    },
    getNbNotificationsFriendly() {
      return this.notifications.length < 100 ? this.notifications.length : '99+';
    },
    getIconForCat(key) {
      const item = this.iconsassoc.filter((a) => a.cat === key);
      if (item.length) {
        return item[0].icon;
      }
      return 'exclamation-triangle';
    },
    getTimeAgo(datetime) {
      return Moment(datetime).fromNow();
    },
    goToNotificationCenter() {
      this.$router.push({ name: 'NotificationCenter' });
    },
    closemodal() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/notification/set-as-read', { notifid: this.currentnotif.id }).then(() => {
        self.currentnotif = null;
        self.loadNotifications();
      });
    },
  },
};
</script>

<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

</style>
