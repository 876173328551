export const ENGLISH_TRANSLATIONS = {
  login: {
    log_in: 'Log in',
    your_email: 'Your email',
    your_password: 'Your password',
    login: 'login',
    lost_password: 'I lost my password',
    reset_password: 'Reset my password',
    change_your_password: 'Change your password',
    your_new_password: 'Your new password',
  },
  homepage: {
    welcome: 'Welcome!',
  },
  other: {
    myaccount: 'My account',
    fullname: 'Full name',
    newpassword: 'New password',
    language: 'Language',
    save: 'Save',
    save_with_success: 'Your account has been updated successfully',
    notifications_notes: 'You can receive notifications that an administrator has set you up on your cell phone via the Pushover app in addition to the <strong>Notifications</strong> section at the top of the screen.',
    downloadpushover: 'Get <a href="https://pushover.net/clients/android">Pushover for Android</a> or <a href="https://pushover.net/clients/ios">Pushover for iPhone/iPad</a>',
    receivenotificationsbymail: 'Receive notifications by email',
  },
  menu: {
    customers: 'Customers',
    logout: 'Log Out',
    myaccount: 'My account',
    flights: 'Flights',
    flight_sync_with_darwin: 'Sync. with Darwin',
    flightslist: 'Flights list',
    search_packages: 'Search for packages',
    search_flights: 'Search cor flights',
    bookings: 'Booking list',
    user: 'Users',
    user_management: 'Users management',
    documentation_center: 'Documentation center',
    documentation_center_view_all: 'Browse',
    scheduleslist: 'Schedules',
    releaseshistory: 'Releases history',
    all_alerts: 'All alerts',
    alerts: 'Alerts',
    tools: 'Tools',
    qr_code_scanner: 'QR code scanner',
    claims: 'Claims',
    list: 'List',
  },
  holasun: {
    no_flight: 'No flight',
    flight_list: 'Flights list',
    all_flights: 'All flights',
    future_flights_only: 'Futur flights only',
    origin: 'Origin',
    destination: 'Destination',
    flight_num: 'Flight num.',
    departure_date: 'Departure date',
    departure_time: 'Departure time',
    return_time: 'Return time',
    arrival_time: 'Arrival time',
    passengers: 'Passengers',
    passenger_of_flight: 'Passengers of flight',
    departure: 'Departure',
    passengers_manifest: 'Passengers manifest',
    passengers_manifest_addon: 'Manifest Add-ons',
    csv_file: 'CSV file',
    complete_name: 'Fullname',
    birthdate: 'Birthdate',
    telephone: 'Telephone',
    booking: 'Booking',
    save: 'Save',
    no_passenger: 'No passenger',
    select_csv_file: 'Choose a CSV file',
    paxes_management: 'Paxes management',
    departures_table_view: 'Departures board',
  },
  users: {
    users_list: 'Users list',
    user_details: 'User details',
    user_add: 'Add a new user',
    name: 'Fullname',
    email: 'Email address',
    accesslevel: 'Access level',
    lang_welcome_email: 'Welcome email language',
    add: 'Add',
    new_password: 'New password',
    edit: 'Edit',
    last_logins: 'Last connections',
    lastconnection: 'Last connexion',
    origin: 'Origin',
    no_activity: 'No activity',
    details: 'Details',
    delete: 'Delete',
    are_you_sure: 'Are you sure?',
    delete_warning: 'You are about to delete the user',
    yes: 'Yes',
    no: 'No',
    notificationstypes: 'Type of notifications to send to this user',
  },
  searchengine: {
    booking_num: 'Booking number',
    gender: 'Gender',
    firstname: 'Firstname',
    lastname: 'Lastname',
    search: 'Search',
    holasun_booking_search: 'Bookings search engine',
    no_result: 'No result',
    choose_a_booking: 'Choose a booking',
    flights: 'Flights',
    passengers: 'Passengers',
    choose: 'Choose',
    cancel: 'Cancel',
    booking: 'Booking',
    birthdate: 'Birthdate',
    email: 'Email',
    phone_number: 'Telephone',
    seat: 'Seat',
    ssr: 'SSR',
    additionalbag: 'additionalbag',
    secondbag: 'secondbag',
    owtoszbag: 'owtoszbag',
    selectclass: 'selectclass',
    ife: 'ife',
    notes: 'Notes',
    added_at: 'Added at',
    by: 'By',
    for: 'For',
    no_note: 'No note',
    new_note: 'New note',
    for_passenger: 'For the passenger',
    save: 'Save',
    deleted: 'Deleted',
    booking_not_found: 'Booking not found',
    stats_overview: 'Statis',
    man: 'Man',
    woman: 'Woman',
  },
  syncwithdarwin: {
    syncwithdarwin: 'Synchronize flights informations with Darwin',
    startsync: 'Start synchronization',
    syncinprogress: 'Synchronization in progress...',
    newschedulesretrival: 'Retrieval of new schedules',
    nonewscheduletoimport: 'No new schedules to import',
    scheduletoimport: 'Schedules to import',
    gateways: 'Gateways',
    flightnum: 'Flight Num.',
    daysofoperation: 'Days of operation',
    tags: 'Tags',
    importnewschedules: 'Import new schedules',
    savinginprogress: 'Saving in progress',
    success: 'Success',
    successimport: 'Import successfully completed!',
    newflightsadded: 'new flights added',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  publictemplate: {
    aboutus: 'About us',
    aboutus_url: 'https://owg.com/en/about-us/',
    ourfleet: 'Our fleet',
    ourfleet_url: 'https://owg.com/en/our-fleet/',
    faq: 'FAQ',
    faq_url: 'https://owg.com/en/faq/',
    news: 'News',
    news_url: 'https://owg.com/en/news/',
    footer_plan_your_trip: 'Plan your trip',
    footer_general_informations: 'General information',
    footer_general_informations_url: 'https://owg.com/en/travel-info/general-information/',
    footer_baggages: 'Baggages',
    footer_baggages_url: 'https://owg.com/en/baggages/',
    footer_covid_19: 'Covid-19',
    footer_covid_19_url: 'https://owg.com/en/covid-19/',
    footer_families_children: 'Families and Children',
    footer_families_children_url: 'https://owg.com/en/travel-info/families-and-children/',
    footer_specials_services: 'Special Services',
    footer_specials_services_url: 'https://owg.com/en/travel-info/special-services/mobility-and-medical-equipment/',
    footer_airports: 'Airports',
    footer_airports_url: 'https://owg.com/en/travel-info/airports/',
    footer_in_flight: 'In-flight',
    footer_in_flight_url: 'https://owg.com/en/travel-info/in-flight/',
    footer_travelers_rights: 'Traveler rights',
    footer_travelers_rights_url: 'https://owg.com/en/travel-info/traveler-rights/delays-cancellations-schedule-changes-and-baggage-coverage/',
    footer_faq: 'FAQ',
    footer_faq_url: 'https://owg.com/en/faq/',
    footer_our_campany: 'Our company',
    footer_about_us: 'About us',
    footer_about_us_url: 'https://owg.com/en/about-us/',
    footer_our_fleet: 'Our fleet',
    footer_our_fleet_url: 'https://owg.com/en/our-fleet/',
    footer_wallpapers: 'Wallpapers',
    footer_wallpapers_url: 'https://owg.com/en/wallpapers/',
    footer_customer_support: 'Customer support',
    footer_call_us: 'Call us',
    footer_charter_services: 'Charter services',
    footer_charter_flights_for_groups: 'Charter flights for groups and events',
    footer_charter_flights_for_groups_url: 'https://owg.com/en/travel-info/charter-flights-for-groups-and-events/',
    footer_permit: '© 2022 OWG Voyages is the holder of a Quebec permit #703117',
    footer_contact: 'Contact',
    footer_contact_url: 'https://owg.com/en/contact/',
    footer_tariff: 'Tariff',
    footer_tariff_url: 'https://owg.com/wp-content/uploads/2021/10/TARIFF-No.5-VERSION-2-REV16-MEXICO-ADD-ON-FILED-09-SEPT-2021-PURSUANT-TO-CTA-ORDER-2021-A-3_rev-by-CS_CTA_10SEP21-1.pdf',
    footer_terms_of_use: 'Terms of use',
    footer_terms_of_use_url: 'https://owg.com/en/terms-of-use/',
    footer_privacy_policy: 'Privacy Policy',
    footer_privacy_policy_url: 'https://owg.com/en/privacy-policy/',
  },
  webcheckinroot: {
    checkin: 'Check-in',
    backtohomepage: 'Back to home page',
    bookingnumber: 'Booking number',
    lastname: 'Last name',
    search: 'Search',
    webcheckinwelcome: 'Welcome to Web Check-in for your flight',
    maintxt1: 'Web Check-in is available between 24 hours, and up to 60 minutes prior to the scheduled departure time.',
    maintxt2: 'You must have current and valid documentation (e.g. valid passport).',
    maintxt3: 'At the airport, you will be required to drop off your baggage at the OWG Check-in counter.',
    maintxt4: 'Our Web check-in will allow you to conveniently:',
    feature1: 'Check in<br>for your flight',
    feature2: 'Reserve<br>your seats',
    feature3: 'Check in<br> your baggage',
    feature4: 'Obtain your<br>boarding pass',
  },
  webcheckinpaxesselect: {
    paxestocheckin: 'Passengers to check in',
    flight: 'Flight',
    planneddeparture: 'Departure scheduled for',
    plannedarrival: 'Arrival scheduled for',
    choosepassengers: 'Select the passengers you want to check in',
    startcheckin: 'Start check in',
  },
  webcheckinagreement: {
    hazardous: 'Hazardous materials',
    liquid: 'Liquids, Aerosols and Gels',
    explosives: 'Explosives',
    flammables: 'Flammables',
    oxidizers: 'Oxidizers',
    corrosives: 'Corrosives',
    radioactive: 'Radioactive',
    toxins: 'Toxins',
    bottomtext: 'Hazardous materials are not allowed on board. By continuing, you agree (on behalf of everyone you’re checking in) to the hazardous materials policy and liquids, aerosols and gels regulations.',
    close: 'Close',
    nextstep: 'Next step',
  },
  webcheckinpaxinfos: {
    passengers: 'Passengers',
    checkedin: 'checked in',
    gender: 'Gender',
    birthdate: 'Birthdate',
    nationality: 'Nationality',
    checkedbaggages: 'Checked baggages',
    oversize: 'Oversize',
    checkedbaggagesnotice: 'If you have baggage to check in, you will need to go to the counter to drop off your baggage.',
    traveldocument: 'Travel document',
    documenttype: 'Travel document type',
    search: 'Search',
    documentnumber: 'Travel document number',
    documentissuingcountry: 'Travel document issuing country',
    traveldocumentexpirationdate: 'Expiration date',
    contactinformations: 'Contact informations',
    email: 'Email',
    cellphone: 'Cell phone number',
    consentcheckbox: 'I agree to be contacted by email and text to be informed of changes to my flight.',
    healthcheck: 'Health check',
    chooseqrcodeimage: 'Choose QR code image',
    vaccinepassportok: 'Compliant vaccination passport',
    novaccinepassport: 'No vaccination passport has yet been provided',
    secureflightdetails: 'Secure flight details',
    secureflightnotice: 'Text for this section',
    redressnumber: 'Redress number',
    knowntravelernumber: 'Known traveler number',
    nextstep: 'Next step',
  },
  nextflightswidget: {
    nextdays: 'days',
    nextdepartures: 'Next departures',
    showall: 'Show all',
    showless: 'Show less',
    displaying: 'Displaying',
    departures: 'departures',
    nodeparturesinthe: 'No departures in the next',
    days: 'days',
  },
  documentcenter: {
    documentationcenter: 'Documentation center',
    alldocuments: 'All documents',
    nodocumentavailable: 'No documents available',
    adddocument: 'Add a document',
    choosepdffile: 'Choose a PDF file',
    file: 'file',
    fileavailableinfrench: 'File available in french',
    frenchname: 'French name',
    fileavailableinenglish: 'File available in english',
    englishname: 'English name',
    add: 'Add',
    deletewarning: 'You are about to delete the document',
    fileinfo: 'File Information',
    filename: 'File name',
    langs: 'Languages',
    download: 'Download',
    close: 'Close',
    filesavesuccess: 'File saved successfully',
    uploaderror: 'A problem occurred while saving the file',
  },
  flightscalendar: {
    flightscalendar: 'Flights calendar',
    flightinfos: 'Flight informations',
    departuredate: 'Departure date',
    arrivaldate: 'Arrival date',
    origin: 'Departure gateway',
    destination: 'Arrival gateway',
    flightnum: 'Flight number',
    aircraft: 'Aircraft',
    paxesseats: 'Passengers / seats',
    tags: 'Tags',
    showalldetails: 'View all details',
    close: 'Close',
    showhiddenflights: 'Show hidden flights',
  },
  scheduleslist: {
    scheduleslist: 'Flight schedules list',
    showhiddenschedules: 'Show hidden schedules',
    showpasschedules: 'show past schedules',
    operatingdates: 'Operating dates',
    gateways: 'Gateways',
    flightnum: 'Flight number',
    operatingdays: 'Days of operation',
    hidden: 'Hidden',
  },
  flightdetails: {
    flightdetails: 'Flight details',
    generalinfos: 'General informations',
    flightnum: 'Flight num',
    aircraft: 'Aircraft',
    passengers_seats: 'Passengers / Seats',
    tags: 'Tags',
    origin: 'Origin',
    destination: 'Destination',
    planneddeparture: 'Planned departure',
    plannedarrival: 'Planned arrival',
    passengers: 'Passengers',
    booking: 'Booking',
    nobookingfound: 'No booking',
  },
  alerts: {
    type: 'Type',
    startat: 'Start date',
    endat: 'End date',
    content: 'Content',
    title: 'Title',
    add: 'Add',
    save: 'Save',
  },
  publicalerts: {
    error: 'Error',
    notfound: 'Page not found',
  },
};
