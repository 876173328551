<template>
  <div>
    <mdb-card>
      <mdb-card-header color="grey darken-3">{{$t('nextflightswidget.nextdepartures')}} | {{nbdays}} {{$t('nextflightswidget.nextdays')}}</mdb-card-header>
      <mdb-card-body>
        <mdb-spinner v-if="isloading" />
        <mdb-alert color="info" v-if="!isloading && !flights.length">{{$t('nextflightswidget.nodeparturesinthe')}} {{nbdays}} {{$t('nextflightswidget.days')}}</mdb-alert>
        <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
        <mdb-tbl v-if="!isloading && flights.length" responsive>
          <mdb-tbl-head>
            <tr>
              <th>Date</th>
              <th>Num.</th>
              <th>{{$t('syncwithdarwin.gateways')}}</th>
              <th>Paxes / Seats</th>
              <th>Blacklist</th>
              <th>Darwin</th>
              <th>Check-in</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr v-for="flight in orderedflight" v-bind:key="flight.id" v-bind:class="{ past: new Date(flight.departuredatetime).getTime() < new Date().getTime()}" v-on:click="showflightDetails(flight.id)">
              <td>
                {{formatDate(flight.departuredatetime)}}
                <div v-if="flight.crewchangedetected">
                  <mdb-alert color="warning">Warning: Crew change detected in FDS</mdb-alert>
                </div>
              </td>
              <td>{{flight.flightnum}}</td>
              <td>{{flight.origin}} <mdb-icon icon="long-arrow-alt-right" size="1x" /> {{flight.destination}}</td>
              <td>{{flight.nbpaxes}} / {{flight.nbseats}}</td>
              <td>
                <mdb-icon icon="check" color="success" size="1x" v-if="flight.nbblacklist === 0" />
                <mdb-icon icon="exclamation-triangle" color="danger" size="1x" v-else />
              </td>
              <td>
                <mdb-icon icon="check" color="success" size="1x" v-if="flight.isdarwinok" />
                <mdb-icon icon="exclamation-triangle" color="danger" size="1x" v-else />
              </td>
              <td>{{flight.nbcheckedin}} / {{flight.nbpaxes}}</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
        <mdb-row v-if="flights.length">
          <mdb-col md="6">
            <div v-if="!showall">
              <mdb-badge pill color="green">
                {{$t('nextflightswidget.displaying')}} {{nbflights}}/{{flights.length}} {{$t('nextflightswidget.departures')}}
              </mdb-badge>
            </div>
          </mdb-col>
          <mdb-col md="6" class="text-right">
            <mdb-btn size="sm" outline="elegant" v-if="!showall" v-on:click="showall=true">{{$t('nextflightswidget.showall')}}</mdb-btn>
            <mdb-btn size="sm" outline="elegant" v-else v-on:click="showall=false">{{$t('nextflightswidget.showless')}}</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbAlert,
  mdbIcon,
  mdbSpinner,
  mdbRow,
  mdbCol,
  mdbBadge,
  mdbBtn,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'nextdepartureswidget',
  props: {
    nbflights: {
      type: Number,
      default: 5,
    },
    nbdays: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      flights: [],
      isloading: true,
      error: null,
      showall: false,
    };
  },
  computed: {
    orderedflight() {
      const sorted = this.flights;
      function compare(a, b) {
        if (new Date(a.departuredatetime).getTime() < new Date(b.departuredatetime).getTime()) {
          return -1;
        }
        if (new Date(a.departuredatetime).getTime() > new Date(b.departuredatetime).getTime()) {
          return 1;
        }
        return 0;
      }
      if (!this.showall) {
        return sorted.sort(compare).slice(0, this.nbflights);
      }
      return sorted.sort(compare);
    },
  },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbAlert,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
    mdbSpinner,
    mdbRow,
    mdbCol,
    mdbBadge,
    mdbBtn,
  },
  mounted() {
    this.loadnextflights();
  },
  methods: {
    loadnextflights() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/flight/get-flights-abstract-next-days', { nbdays: this.nbdays }).then((response) => {
        self.isloading = false;
        if (response.status === 'OK') {
          self.flights = response.data.flights;
        } else {
          self.error = response.msg;
        }
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    showflightDetails(flightid) {
      this.$router.push({ name: 'FlightDetails', params: { id: flightid } });
    },
  },
};
</script>

<style scoped>
tbody tr:hover{
  background-color: #f5f5f5;
}
tbody tr{
  cursor: pointer;
}
tbody tr.past{
  background-color: #e8f5e9;
}

tbody tr:hover.past{
  background-color: #f5f5f5;
}
</style>
