import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userisloggued: false,
    token: '',
    fullname: '',
    accessmode: '',
    currentlang: '',
    sidenavtoggle: true,
    messages: [],
    bypasscodes: ['67KCG64vABar', '666MjhKJW3Zq'],
    bypassed: false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
