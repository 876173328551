import axios from 'axios';
import store from '../store/index';
import Config from './Config';

export default class Auth {
  isloggued = false;

  authreturn = {
    status: 'OK',
    msg: '',
    token: '',
    data: {
      mustuse2fatoconnect: false,
    },
  };

  header = {
    headers: {
      'Accept-Language': 'fr',
      Accept: 'application/json',
      Authorization: '',
    },
  };

  canaccesstopage = false;

  getHeaders() {
    this.header = {
      headers: {
        'Accept-Language': store.state.currentlang,
        Accept: 'application/json',
        Authorization: '',
      },
    };
    return this.header;
  }

  logout() {
    this.isloggued = false;
    store.state.userisloggued = false;
    store.state.token = '';
    window.localStorage.removeItem('token');
    return true;
  }

  async isLogged() {
    if (store.state.userisloggued) {
      this.isloggued = true;
      return this.isloggued;
    }

    const thetoken = window.localStorage.getItem('token');

    if (thetoken) {
      const self = this;
      const domain = Config.basedomain;
      const headers = this.getHeaders();
      headers.headers.Authorization = `Bearer ${thetoken}`;
      return axios.get(`${domain}api/auth/isloggued`, headers).then((response: any) => {
        if (response.data.status === 'OK') {
          self.isloggued = true;
          store.state.userisloggued = true;
          store.state.token = thetoken;
          store.state.fullname = response.data.data.fullname;
          store.state.accessmode = response.data.data.accesslevel;
          store.state.messages = response.data.data.messages;
          return self.isloggued;
        }
        self.isloggued = false;
        store.state.userisloggued = false;
        store.state.token = '';
        return self.isloggued;
      }).catch(() => {
        self.isloggued = false;
        store.state.userisloggued = false;
        return self.isloggued;
      });
    }
    return this.isloggued;
  }

  async login(_email: string, _password: string, _code2fa: string) {
    const self = this;
    const domain = Config.basedomain;

    return axios.post(`${domain}api/auth/login`, { email: _email, password: _password, code2fa: _code2fa }, this.getHeaders()).then((response: any) => {
      if (response.data.status === 'OK') {
        if (response.data.data.mustuse2fatoconnect) {
          self.authreturn.data.mustuse2fatoconnect = true;
        } else {
          self.authreturn.token = response.data.data.bearer;
          window.localStorage.setItem('token', self.authreturn.token);
          store.state.userisloggued = true;
          store.state.token = response.data.data.bearer;
          store.state.fullname = response.data.data.fullname;
          store.state.accessmode = response.data.data.accesslevel;
          store.state.messages = response.data.data.messages;
        }
        return self.authreturn;
      }
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.data.msg;
      return self.authreturn;
    }).catch((response: any) => {
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.data.msg;
      return self.authreturn;
    });
  }

  async sendResetPwdEmail(_email: string) {
    const self = this;
    const domain = Config.basedomain;

    return axios.post(`${domain}api/auth/lost-password`, { email: _email }, this.getHeaders()).then((response: any) => {
      self.authreturn.status = response.data.status;
      self.authreturn.msg = response.data.msg;
      return self.authreturn;
    }).catch((response: any) => {
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.response.data.msg;
      return self.authreturn;
    });
  }

  async checkrequest(_token: string) {
    const self = this;
    const domain = Config.basedomain;
    return axios.post(`${domain}api/auth/validate-password-change-token`, { token: _token }).then((response: any) => {
      self.authreturn.status = response.data.status;
      if (self.authreturn.status === 'OK') {
        self.authreturn.data = response.data.data;
      }
      self.authreturn.msg = response.data.msg;
      return self.authreturn;
    }).catch((response: any) => {
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.response.data.msg;
      return self.authreturn;
    });
  }

  async changePasswordWithToken(_token: string, _newpwd: string) {
    const self = this;
    const domain = Config.basedomain;
    return axios.post(`${domain}api/auth/change-password`, { token: _token, newpwd: _newpwd }, this.getHeaders()).then((response: any) => {
      self.authreturn.status = response.data.status;
      if (self.authreturn.status === 'OK') {
        self.authreturn.data = response.data.data;
      }
      self.authreturn.msg = response.data.msg;
      return self.authreturn;
    }).catch((response: any) => {
      self.authreturn.status = 'ERROR';
      self.authreturn.msg = response.response.data.msg;
      return self.authreturn;
    });
  }

  canAccess(to:any) {
    if (to.meta.canaccess.includes('*')) {
      this.canaccesstopage = true;
    }
    const self = this;
    if (to.meta.canaccess.includes(store.state.accessmode) || store.state.accessmode === 'FULLACCESS') {
      self.canaccesstopage = true;
    }
    return this.canaccesstopage;
  }
}
