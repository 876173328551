export const FRENCH_TRANSLATIONS = {
  login: {
    log_in: 'Connexion',
    your_email: 'Adresse courriel',
    your_password: 'Mot de passe',
    login: 'Connexion',
    lost_password: 'J\'ai perdu mon mot de passe',
    reset_password: 'Réinitialiser mon mot de passe',
    change_your_password: 'Modifiez votre mot de passe',
    your_new_password: 'Votre nouveau mot de passe',
  },
  homepage: {
    welcome: 'Bienvenue!',
  },
  other: {
    myaccount: 'Mon compte',
    fullname: 'Nom complet',
    newpassword: 'Nouveau mot de passe',
    language: 'Langue',
    save: 'Sauvegarder',
    save_with_success: 'Votre compte a été mis à jour avec succès',
    notifications_notes: 'Vous pouvez recevoir les notifications qu\'un administrateur vous a configuré sur votre cellulaire via l\'application Pushover en plus de la section <strong>Notifications</strong> en haut de l\'écran.',
    downloadpushover: 'Téléchargez <a href="https://pushover.net/clients/android">Pushover pour Android</a> or <a href="https://pushover.net/clients/ios">Pushover pour iPhone/iPad</a>',
    receivenotificationsbymail: 'Recevoir les notifications par courriel',
  },
  menu: {
    customers: 'Clients',
    logout: 'Déconnexion',
    myaccount: 'Mon compte',
    flights: 'Vols',
    flight_sync_with_darwin: 'Synchroniser avec Darwin',
    flightslist: 'Liste des vols',
    search_packages: 'Recherche de forfaits',
    search_flights: 'Recherche de vols',
    bookings: 'Liste des réservations',
    user: 'Utilisateurs',
    user_management: 'Gestion des utilisateurs',
    documentation_center: 'Centre de documentation',
    documentation_center_view_all: 'Consulter',
    scheduleslist: 'Horaires de vol',
    releaseshistory: 'Historique de déploiement',
    all_alerts: 'Toutes les alertes',
    alerts: 'Alertes',
    tools: 'Outils',
    qr_code_scanner: 'Validation QR code',
    claims: 'Réclamations',
    list: 'Liste',
  },
  holasun: {
    no_flight: 'Aucun vol',
    flight_list: 'Liste des vols',
    all_flights: 'Tous les vols',
    future_flights_only: 'Vols futurs seulement',
    origin: 'Origine',
    destination: 'Destination',
    flight_num: 'Num. de vol',
    departure_date: 'Date de départ',
    departure_time: 'Heure de départ',
    return_time: 'Heure de retour',
    arrival_time: 'Heure d\'arrivée',
    passengers: 'Passagers',
    passenger_of_flight: 'Passagers du vol',
    departure: 'Départ le',
    passengers_manifest: 'Manifeste des passagers',
    passengers_manifest_addon: 'Manifeste Add-ons',
    csv_file: 'Fichier CSV',
    complete_name: 'nom complet',
    birthdate: 'Date de naissance',
    telephone: 'Téléphone',
    booking: 'Réservation',
    save: 'Enregistrer',
    no_passenger: 'Aucun passager',
    select_csv_file: 'Veuillez sélectionner un fichier CSV',
    paxes_management: 'Gestion des passagers',
    stats_overview: 'Statistiques',
    departures_table_view: 'Tableau des départs',
  },
  users: {
    users_list: 'Liste des utilisateurs',
    user_details: 'Détails de l\'utilisateur',
    user_add: 'Ajout d\'un utilisateur',
    name: 'Nom complet',
    email: 'Adresse courriel',
    accesslevel: 'Niveau d\'accès',
    lang_welcome_email: 'Langue du courriel de bienvenue',
    add: 'Ajouter',
    new_password: 'Nouveau mot de passe',
    edit: 'Modifier',
    last_logins: 'Dernières connexions',
    lastconnection: 'Dernière connexion',
    origin: 'Origine',
    no_activity: 'Aucune activité',
    details: 'Détails',
    delete: 'Supprimer',
    are_you_sure: 'Êtes-vous certain?',
    delete_warning: 'Vous êtes sur le point de supprimer l\'utilisateur',
    yes: 'Oui',
    no: 'Non',
    notificationstypes: 'Type de notifications à envoyer à cet utilisateur',
  },
  searchengine: {
    booking_num: 'Numéro de réservation',
    gender: 'Sexe',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    search: 'Chercher',
    holasun_booking_search: 'Recherche de réservation',
    no_result: 'Aucun résultat',
    choose_a_booking: 'Sélectionnez une réservation',
    flights: 'Vols',
    passengers: 'Passagers',
    choose: 'Sélectionner',
    cancel: 'Annuler',
    booking: 'Réservation',
    birthdate: 'Date de naissance',
    email: 'Courriel',
    phone_number: 'Téléphone',
    seat: 'Siège',
    ssr: 'SSR',
    additionalbag: 'additionalbag',
    secondbag: 'secondbag',
    owtoszbag: 'owtoszbag',
    selectclass: 'selectclass',
    ife: 'ife',
    notes: 'Notes au dossier',
    added_at: 'Ajouté le',
    by: 'Par',
    for: 'Pour',
    no_note: 'Aucune note',
    new_note: 'Nouvelle note',
    for_passenger: 'Pour le passager',
    save: 'Sauvegarder',
    deleted: 'Supprimé',
    booking_not_found: 'Réservation introuvable',
    man: 'Homme',
    woman: 'Femme',
  },
  syncwithdarwin: {
    syncwithdarwin: 'Synchroniser les informations de vols avec Darwin',
    startsync: 'Démarrer la synchronisation',
    syncinprogress: 'Synchronisation en cours ...',
    newschedulesretrival: 'Récupération des nouvels horaires',
    nonewscheduletoimport: 'Aucun nouvel horaire à importer',
    scheduletoimport: 'Horaires à importer',
    gateways: 'Aéroports',
    flightnum: 'Num. de vol',
    daysofoperation: 'Jours d\'opération',
    tags: 'Catégories',
    importnewschedules: 'Importer les nouvels horaires',
    savinginprogress: 'Enregistrement en cours ...',
    success: 'Succès',
    successimport: 'Importation effectuée avec succès!',
    newflightsadded: 'nouveaux vols ajoutés',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
  },
  publictemplate: {
    aboutus: 'À propos',
    aboutus_url: 'https://owg.com/a-propos/',
    ourfleet: 'Nos aéronefs',
    ourfleet_url: 'https://owg.com/nos-aeronefs/',
    faq: 'FAQ',
    faq_url: 'https://owg.com/faq/',
    news: 'Nouvelles',
    news_url: 'https://owg.com/nouvelles/',
    footer_plan_your_trip: 'Planifier votre voyage',
    footer_general_informations: 'Informations générales',
    footer_general_informations_url: 'https://owg.com/informations-de-voyage/informations-generales/',
    footer_baggages: 'Bagages',
    footer_baggages_url: 'https://owg.com/bagages/',
    footer_covid_19: 'Covid-19',
    footer_covid_19_url: 'https://owg.com/covid-19/',
    footer_families_children: 'Familles et enfants',
    footer_families_children_url: 'https://owg.com/informations-de-voyage/familles-et-enfants/',
    footer_specials_services: 'Services spéciaux',
    footer_specials_services_url: 'https://owg.com/informations-de-voyage/special-services/aide-a-la-mobilite-et-equipement-medical/',
    footer_airports: 'Aéroports',
    footer_airports_url: 'https://owg.com/informations-de-voyage/airports/',
    footer_in_flight: 'Services en vol',
    footer_in_flight_url: 'https://owg.com/informations-de-voyage/services-en-vol/',
    footer_travelers_rights: 'Droits des voyageurs',
    footer_travelers_rights_url: 'https://owg.com/informations-de-voyage/droits-des-voyageurs/retards-annulations-changements-dhoraire-et-couverture-bagage/">Droits des voyageurs',
    footer_faq: 'FAQ',
    footer_faq_url: 'https://owg.com/faq/',
    footer_our_campany: 'L’entreprise',
    footer_about_us: 'À propos',
    footer_about_us_url: 'https://owg.com/a-propos/',
    footer_our_fleet: 'Nos aéronefs',
    footer_our_fleet_url: 'https://owg.com/nos-aeronefs/',
    footer_wallpapers: 'Fonds d’écran',
    footer_wallpapers_url: 'https://owg.com/fonds-decran/',
    footer_customer_support: 'Service à la clientèle',
    footer_call_us: 'Nous appeler',
    footer_charter_services: 'Nolisement',
    footer_charter_flights_for_groups: 'Nolisement pour groupes et événements',
    footer_charter_flights_for_groups_url: 'https://owg.com/informations-de-voyage/nolisement-pour-groupes-et-evenements/',
    footer_permit: '© 2022 OWG Voyages, est titulaire d’un permis du Quebec no 703117',
    footer_contact: 'Contact',
    footer_contact_url: 'https://owg.com/contact/',
    footer_tariff: 'Tarif',
    footer_tariff_url: 'https://owg.com/wp-content/uploads/2021/10/TARIFF-No.5-VERSION-2-REV16-MEXICO-ADD-ON-FILED-09-SEPT-2021-PURSUANT-TO-CTA-ORDER-2021-A-3_rev-by-CS_CTA_10SEP21-1.pdf',
    footer_terms_of_use: 'Conditions d’utilisation',
    footer_terms_of_use_url: 'https://owg.com/conditions-dutilisation/',
    footer_privacy_policy: 'Politique de confidentialité',
    footer_privacy_policy_url: 'https://owg.com/politique-de-confidentialite/',
  },
  webcheckinroot: {
    checkin: 'Enregistrement',
    backtohomepage: 'Retour à la page d\'accueil',
    bookingnumber: 'Numéro de réservation',
    lastname: 'Nom de famille',
    search: 'Rechercher',
    webcheckinwelcome: 'Bienvenue à l\'enregistrement en ligne pour votre vol !',
    maintxt1: 'L\'enregistrement en ligne est disponible entre 24 heures et 60 minutes avant l\'heure de départ prévue',
    maintxt2: 'Vous devez être en possession de documents valides (par exemple, un passeport valide).',
    maintxt3: 'À l\'aéroport, vous devrez déposer vos bagages au comptoir d\'enregistrement de OWG.',
    maintxt4: 'Notre enregistrement en ligne vous permettra de le faire de manière pratique',
    feature1: 'Enregistrement<br>pour votre vol',
    feature2: 'Choisissez<br>vos sièges',
    feature3: 'Enregistrement<br>de vos bagages',
    feature4: 'Obtenez votre<br>carte d\'embarquement',
  },
  webcheckinpaxesselect: {
    paxestocheckin: 'Passagers à enregistrer',
    flight: 'Vol',
    planneddeparture: 'Départ prévu à',
    plannedarrival: 'Arrivée prévue à',
    choosepassengers: 'Sélectionnez les passagers que vous voulez enregistrer',
    startcheckin: 'Démarrer l\'enregistrement',
  },
  webcheckinagreement: {
    hazardous: 'Matières dangereuses',
    liquid: 'Liquides, aérosols et gels',
    explosives: 'Explosifs',
    flammables: 'Inflammables',
    oxidizers: 'Oxydants',
    corrosives: 'Corrosifs',
    radioactive: 'Radioactifs',
    toxins: 'Toxines',
    bottomtext: 'Les matières dangereuses ne sont pas autorisées à bord. En continuant, vous acceptez (au nom de toutes les personnes que vous enregistrez) la politique relative aux matières dangereuses et les règlements sur les liquides, aérosols et gels.',
    close: 'Fermer',
    nextstep: 'Prochaine étape',
  },
  webcheckinpaxinfos: {
    passengers: 'Passagers',
    checkedin: 'Enregistré',
    gender: 'Sexe',
    birthdate: 'Date de naissance',
    nationality: 'Nationalité',
    checkedbaggages: 'Bagages enregistrés',
    oversize: 'surdimensionné',
    checkedbaggagesnotice: 'Si vous avez un bagage à enregistrer, vous devrez vous présenter au comptoir pour y déposer votre bagage',
    traveldocument: 'Documents de voyage',
    documenttype: 'Type de document de voyage',
    search: 'Chercher',
    documentnumber: 'Numéro du document',
    documentissuingcountry: 'Pays d\'émission du document',
    traveldocumentexpirationdate: 'Date d\'expiration',
    contactinformations: 'Informations de contact',
    email: 'Adresse courriel',
    cellphone: 'Numéro de cellulaire',
    consentcheckbox: 'Je consens à être contacté par courriel et sms pour être informé des changements de mon vol.',
    healthcheck: 'Passeport vaccinal',
    chooseqrcodeimage: 'Sélectionner le qr code du passeport vaccinal',
    vaccinepassportok: 'Passeport vaccinal conforme',
    novaccinepassport: 'Aucun passeport vaccinal n\'a encore été fourni',
    secureflightdetails: 'Détails du vol sécurisé',
    secureflightnotice: 'Texte pour cette section',
    redressnumber: 'Numéro de recours',
    knowntravelernumber: 'Numéro de voyageur connu',
    nextstep: 'Étape suivante',
  },
  nextflightswidget: {
    nextdays: 'jours',
    days: 'prochains jours',
    nextdepartures: 'Prochains départs',
    showall: 'Tout afficher',
    showless: 'Afficher moins',
    displaying: 'Affichage de',
    departures: 'départs',
    nodeparturesinthe: 'Aucun départ de prévu dans les',
  },
  documentcenter: {
    documentationcenter: 'Centre de documentation',
    alldocuments: 'Tous les documents',
    nodocumentavailable: 'Aucun document de disponible',
    adddocument: 'Ajout d\'un document',
    choosepdffile: 'Choisissez un fichier PDF',
    file: 'Fichier',
    fileavailableinfrench: 'Fichier disponible en français',
    frenchname: 'nom français',
    fileavailableinenglish: 'Fichier disponible en anglais',
    englishname: 'nom anglais',
    add: 'Ajouter',
    deletewarning: 'Vous êtes sur le point de supprimer le document',
    fileinfo: 'Informations sur le fichier',
    filename: 'Nom du fichier',
    langs: 'Langues',
    download: 'Téléchargement',
    close: 'Fermer',
    filesavesuccess: 'Fichier sauvegardé avec succès',
    uploaderror: 'Un problème s\'est produit lors de la sauvegarde du fichier',
  },
  flightscalendar: {
    flightscalendar: 'Calendrier des vols',
    flightinfos: 'Informations sur le vol',
    departuredate: 'Date de départ',
    arrivaldate: 'Date d\'arrivée',
    origin: 'Aéroport de départ',
    destination: 'Aéroport d\'arrivé',
    flightnum: 'Numéro de vol',
    aircraft: 'Appareil',
    paxesseats: 'Passagers / sièges',
    tags: 'Étiquettes',
    showalldetails: 'Voir tous les détails',
    close: 'Fermer',
    showhiddenflights: 'Afficher les vols cachés',
  },
  scheduleslist: {
    scheduleslist: 'Liste des horaires de vol',
    showhiddenschedules: 'Afficher les schedules cachées',
    showpasschedules: 'Afficher les schedules passées',
    operatingdates: 'Dates d\'opération',
    gateways: 'Aéroports',
    flightnum: 'Numéro de vol',
    operatingdays: 'Jours d\'opération',
    hidden: 'Cachée',
  },
  flightdetails: {
    flightdetails: 'Détails d\'un vol',
    generalinfos: 'Informations générales',
    flightnum: 'Numéro de vol',
    aircraft: 'Appareil',
    passengers_seats: 'Passagers / Sièges',
    tags: 'Étiquettes',
    origin: 'Origine',
    destination: 'Destination',
    planneddeparture: 'Départ prévu',
    plannedarrival: 'Arrivée prévue',
    passengers: 'Passagers',
    booking: 'Réservation',
    nobookingfound: 'Aucune réservation',
  },
  alerts: {
    type: 'Type',
    startat: 'Date de début',
    endat: 'Date de fin',
    content: 'Contenu',
    title: 'Titre',
    add: 'Ajouter',
    save: 'Sauvegarder',
  },
  publicalerts: {
    error: 'Erreur',
    notfound: 'Page introuvable',
  },
};
