<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-row v-if="$store.state.messages.length">
              <mdb-col>
                <mdb-alert v-for="(message,index) in $store.state.messages" v-bind:key="index" :color="message.color" v-html="message.content"></mdb-alert>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <h1>{{$t('homepage.welcome')}}</h1>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col xl="9" md="9" v-if="$store.state.accessmode === 'AGENT'" class="mb-4">
                <documentcenterwidget />
              </mdb-col>
              <mdb-col xl="12" md="12" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'AGENT'" class="mb-4">
                <nextdepartureswidget :nbflights=5 />
              </mdb-col>
              <mdb-col xl="6" md="6" v-if="$store.state.accessmode === 'FULLACCESS' && false" class="mb-4">
                <aircraftenroutewidget />
              </mdb-col>
              <mdb-col xl="6" md="6" class="mb-4" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'HOLASUN'">
                <mdb-card>
                  <mdb-card-header color="blue darken-3">Holasun</mdb-card-header>
                  <mdb-card-body>
                    <div>
                      <mdb-btn v-on:click="$router.push({name:'Holasun'})" size="sm" color="elegant">{{$t('holasun.paxes_management')}}</mdb-btn>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col xl="6" md="6" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'HOLASUN'">
                <mdb-card>
                  <mdb-card-header color="red darken-3">{{$t('searchengine.holasun_booking_search')}}</mdb-card-header>
                  <mdb-card-body>
                    <div>
                      <div v-if="searchengineerror">
                        <mdb-alert color="danger">{{searchengineerror}}</mdb-alert>
                      </div>
                      <mdb-input :label="$t('searchengine.booking_num')" type="text" v-model="searchenginedata.booking_num" />
                      <mdb-input :label="$t('searchengine.firstname')" type="text" v-model="searchenginedata.firstname" />
                      <mdb-input :label="$t('searchengine.lastname')" type="text" v-model="searchenginedata.lastname" />
                      <mdb-btn color="red" rounded size="md" v-on:click="searchbooking">{{$t('searchengine.search')}}</mdb-btn>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-modal :show="showchooseresult" @close="showchooseresult = false" size="fluid" class="text-center" info centered>
              <mdb-modal-header center :close="false">
                <p class="heading">{{$t('searchengine.choose_a_booking')}}</p>
              </mdb-modal-header>
              <mdb-modal-body>
                <mdb-tbl btn striped bordered>
                  <mdb-tbl-head>
                    <tr>
                      <th>{{$t('searchengine.booking_num')}}</th>
                      <th>{{$t('searchengine.flights')}}</th>
                      <th>{{$t('searchengine.passengers')}}</th>
                      <th>&nbsp;</th>
                    </tr>
                  </mdb-tbl-head>
                  <mdb-tbl-body>
                    <tr v-for="result in searchengineresults" v-bind:key="result.pnr">
                      <td><strong>{{result.pnr}}</strong></td>
                      <td>
                        <mdb-row v-for="flight in orderedsegments(result.flights)" v-bind:key="flight.id">
                          <mdb-col>
                            {{flight.flightnum}}
                          </mdb-col>
                          <mdb-col>
                            <strong>{{flight.depgateway}}</strong>
                            <br />
                            {{getDate(flight.depdate)}} <br />{{getTime(flight.depdate)}}
                          </mdb-col>
                          <mdb-col>
                            <mdb-icon size="2x" class="indigo-text" icon="plane" />
                          </mdb-col>
                          <mdb-col>
                            <strong>{{flight.arrgateway}}</strong>
                            <br />
                            {{getDate(flight.arrdate)}} <br />{{getTime(flight.arrdate)}}
                          </mdb-col>
                        </mdb-row>
                      </td>
                      <td>
                        <mdb-row v-for="pax in result.paxes" v-bind:key="pax.id">
                          <mdb-col>
                            {{pax.firstname}}
                          </mdb-col>
                          <mdb-col>
                            {{pax.lastname}}
                          </mdb-col>
                          <mdb-col>
                            {{pax.birthdate}}
                          </mdb-col>
                        </mdb-row>
                      </td>
                      <td>
                        <mdb-btn color="elegant" v-on:click="choosepnr(result.pnr)">{{$t('searchengine.choose')}}</mdb-btn>
                      </td>
                    </tr>
                  </mdb-tbl-body>
                </mdb-tbl>
              </mdb-modal-body>
              <mdb-modal-footer right>
                <mdb-btn outline="info" v-on:click="showchooseresult = false">{{$t('searchengine.cancel')}}</mdb-btn>
              </mdb-modal-footer>
            </mdb-modal>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbAlert,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbIcon,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import nextdepartureswidget from '@/components/Nextflightdepartureswidget.vue';
import documentcenterwidget from '@/components/Documentcenterwidget.vue';
import aircraftenroutewidget from '@/components/Aircraftenroutewidget.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'homePage',
  data() {
    return {
      dummy: '',
      searchengineerror: null,
      showchooseresult: false,
      searchenginedata: {
        booking_num: '',
        firstname: '',
        lastname: '',
      },
      searchengineresults: [],
    };
  },
  components: {
    owgheader,
    topnavbar,
    nextdepartureswidget,
    documentcenterwidget,
    aircraftenroutewidget,
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbAlert,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbIcon,
  },
  methods: {
    searchbooking() {
      this.searchengineerror = '';
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/holasun/search', { query: self.searchenginedata }).then((response) => {
        if (response.status === 'OK') {
          if (response.data.length === 0) {
            self.searchengineerror = self.$t('searchengine.no_result');
          } else if (response.data.length === 1) {
            self.$router.push({ name: 'HolasunBookingDetails', params: { pnr: response.data[0].pnr } });
          } else {
            self.searchengineresults = response.data;
            self.showchooseresult = true;
          }
        } else {
          self.searchengineerror = response.msg;
        }
        self.isloading = false;
      });
    },
    orderedsegments(list) {
      const flights = [];
      list.forEach((flight) => {
        flights.push(flight);
      });
      function compare(a, b) {
        if (new Date(a.depdate).getTime() < new Date(b.depdate).getTime()) {
          return -1;
        }
        if (new Date(a.depdate).getTime() > new Date(b.depdate).getTime()) {
          return 1;
        }
        return 0;
      }

      return flights.sort(compare);
    },
    choosepnr(_pnr) {
      this.$router.push({ name: 'HolasunBookingDetails', params: { pnr: _pnr } });
    },
    getDate(_thedate) {
      const thedate = new Date(_thedate);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getTime(_thedate) {
      const thedate = new Date(_thedate.replace('T', ' ').replace('Z', ''));
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
  },
};
</script>

<style>
main {
  min-height: 90vh;
}

.fixed-sn footer {
  padding-left: 0;
}

.side-nav .logo-sn {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
}

.navbar .btn, .navbar .nav-item, .navbar .nav-link {
  padding: 0;
}

.select-wrapper input.select-dropdown {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}
.table-ui {
  border: 1px solid #e0e0e9;
}

.fullscreen {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100000;
  min-height: 100vh;
}

.fullscreen-view {
  height: 100vh;
}

.form-control {
  height: calc(2rem + 1px);
  padding: 0.375rem 0.75rem;
}

.list-group .ml-auto {
  float: right;
  flex: none !important;
}

.datepicker-overlay {
  z-index: 9999 !important;
}

.navbar-brand {
  cursor: pointer
}
</style>
