<template>
  <div>
    <mdb-card>
      <mdb-card-header color="unique-color-dark">Appareils en vol</mdb-card-header>
      <mdb-card-body>
        <mdb-spinner v-if="isloading" />
        <mdb-alert color="info" v-if="!isloading && !aircrafts.length">Aucun appareil en vol actuellement</mdb-alert>
        <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
        <mdb-tbl v-if="!isloading && aircrafts.length">
          <mdb-tbl-head>
            <tr>
              <th>Appareil</th>
              <th>Status</th>
              <th>Last seen</th>
              <th>Position</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr v-for="(aircraft,index) in aircrafts" v-bind:key="index" v-on:click="showAircraftDetails(aircraft.call_sign)">
              <td>{{aircraft.call_sign}}</td>
              <td>{{aircraft.status}}</td>
              <td>{{lastseenformatted(aircraft.lastseen)}}</td>
              <td>{{ aircraft.nearcity }} ({{ aircraft.nearcitydistance }})</td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbAlert,
  mdbSpinner,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Moment from 'moment';

export default {
  name: 'currentlyenroutewidget',
  data() {
    return {
      aircrafts: [],
      isloading: true,
      error: null,
    };
  },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbAlert,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbSpinner,
  },
  mounted() {
    const self = this;
    this.loadcurrentlyenroute();
    // setInterval(() => { self.loadcurrentlyenroute(); }, 30000); #on desactive le refresh pour l'instant car ça loop meme en changeant de page
  },
  methods: {
    loadcurrentlyenroute() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/skytrac/get-aircrafts-currently-enroute', { nbdays: this.nbdays }).then((response) => {
        self.isloading = false;
        if (response.status === 'OK') {
          self.aircrafts = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    lastseenformatted(thedate) {
      Moment.locale(this.$store.state.currentlang);
      return Moment(thedate).fromNow();
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    showAircraftDetails(flightid) {
      this.$router.push({ name: 'aircraftdetails', params: { callsign: flightid } });
    },
  },
};
</script>

<style scoped>
tbody tr:hover{
  background-color: #f5f5f5;
}
tbody tr{
  cursor: pointer;
}
tbody tr.past{
  background-color: #e8f5e9;
}

tbody tr:hover.past{
  background-color: #f5f5f5;
}
</style>
