<template>
  <div>
    <div class="black-skin mdb-admin-sidenav">
      <mdb-side-nav logo="/img/OWG-logo.svg" to="/" sideNavClass="sn-bg-3" mask="strong" :OpenedFromOutside.sync="$store.state.sidenavtoggle">
        <li>
          <mdb-side-nav-nav>
            <mdb-side-nav-cat name="Holasun" icon="h-square" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'HOLASUN'">
              <mdb-side-nav-item to="/holasun" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('holasun.paxes_management')}}
              </mdb-side-nav-item>
              <mdb-side-nav-item to="/holasun/table-view" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('holasun.departures_table_view')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.user')" icon="users-cog" v-if="$store.state.accessmode === 'FULLACCESS'">
              <mdb-side-nav-item to="/users" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.user_management')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.flights')" icon="paper-plane" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'AGENT'">
              <mdb-side-nav-item to="/flights/list" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.flightslist')}}
              </mdb-side-nav-item>
              <mdb-side-nav-item to="/flights/schedules" @click.native="width < 1440 && toggleSideNav(false)" v-if="$store.state.accessmode === 'FULLACCESS'">
                {{$t('menu.scheduleslist')}}
              </mdb-side-nav-item>
              <ul v-if="$store.state.accessmode === 'FULLACCESS'">
                <li><hr style="border-top:solid 1px #424242; margin:5px 10px;" /></li>
              </ul>
              <mdb-side-nav-item to="/flights/sync-with-darwin" @click.native="width < 1440 && toggleSideNav(false)" v-if="$store.state.accessmode === 'FULLACCESS'">
                {{$t('menu.flight_sync_with_darwin')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.documentation_center')" icon="file-alt" v-if="$store.state.accessmode === 'FULLACCESS' || $store.state.accessmode === 'AGENT'">
              <mdb-side-nav-item to="/documentation-center" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.documentation_center_view_all')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.alerts')" icon="exclamation" v-if="$store.state.accessmode === 'FULLACCESS'">
              <mdb-side-nav-item to="/alerts" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.all_alerts')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.claims')" icon="life-ring" v-if="$store.state.accessmode === 'FULLACCESS'">
              <mdb-side-nav-item to="/claims" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.list')}}
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
            <mdb-side-nav-cat :name="$t('menu.tools')" icon="tools" v-if="$store.state.accessmode === 'FULLACCESS'">
              <mdb-side-nav-item to="/qr-code-scanner" @click.native="width < 1440 && toggleSideNav(false)">
                {{$t('menu.qr_code_scanner')}}
              </mdb-side-nav-item>
              <mdb-side-nav-item to="/blacklist" @click.native="width < 1440 && toggleSideNav(false)">
                Paxes Blacklist
              </mdb-side-nav-item>
              <mdb-side-nav-item to="/trolley-seal-tags" target="_blank" @click.native="width < 1440 && toggleSideNav(false)">
                Trolley seal tags
              </mdb-side-nav-item>
              <mdb-side-nav-item to="/nolitrak" target="_blank" @click.native="width < 1440 && toggleSideNav(false)">
                Nolitrak
              </mdb-side-nav-item>
            </mdb-side-nav-cat>
          </mdb-side-nav-nav>
        </li>
      </mdb-side-nav>
    </div>
  </div>
</template>

<script>
import {
  mdbSideNav,
  mdbSideNavNav,
  mdbSideNavCat,
  mdbSideNavItem,
  waves,
} from 'mdbvue';

import Auth from '../libs/Auth';

export default {
  name: 'owgheader',
  data() {
    return {
      active: 0,
      elHeight: 0,
      windowHeight: 0,
      toggle: true,
      width: 0,
    };
  },
  components: {
    mdbSideNav,
    mdbSideNavNav,
    mdbSideNavCat,
    mdbSideNavItem,
  },
  methods: {
    logout() {
      const auth = new Auth();
      auth.logout();
      this.$router.push({ name: 'Login' });
    },
    gotohome() {
      this.$router.push({ name: 'Home' });
    },
    setWidth() {
      this.width = window.innerWidth;
    },
    mounted() {
      this.setWidth();
      window.addEventListener('resize', this.setWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setWidth);
    },
    toggleSideNav(v) {
      this.toggle = v;
      this.$store.state.sidenavtoggle = v;
    },
    userHasAccess(locationNames) {
      // si le user a accès à au moins une route, on retourne true
      let canaccess = false;
      const auth = new Auth();
      this.$router.getRoutes().forEach((route) => {
        if (locationNames.includes(route.name) && auth.canAccess(route)) {
          canaccess = true;
        }
      });
      return canaccess;
    },
  },
  watch: {
    toggle(value) {
      this.toggleSideNav(value);
    },
    sidenav(value) {
      this.toggle = value;
    },
    width(value) {
      if (value < 1440) {
        this.toggle = false;
        this.$store.state.sidenavtoggle = false;
      } else {
        this.toggle = true;
        this.$store.state.sidenavtoggle = true;
      }
    },
  },
  mixins: [waves],
};
</script>
