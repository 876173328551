<template>
  <div>
    <mdb-card>
      <mdb-card-header color="grey darken-3">{{$t('documentcenter.documentationcenter')}}</mdb-card-header>
      <mdb-card-body>
        <mdb-spinner v-if="isloading" size="lg" />
        <mdb-alert color="info" v-if="!isloading && !documents.length">{{$t('documentcenter.nodocumentavailable')}}</mdb-alert>
        <mdb-tbl v-if="!isloading && documents.length" sm>
          <mdb-tbl-body>
            <tr v-for="document in documents" v-bind:key="document.id" class="showhover">
              <td>
                <a :href="`/api/document/download?file=${document.filename}`" target="_blank">{{document.name}}</a>
              </td>
              <td>
                <mdb-badge pill color="indigo" v-for="(label,index) in document.langlabels" v-bind:key="index" class="mr-1">{{label}}</mdb-badge>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import {
  mdbTbl,
  mdbTblBody,
  mdbAlert,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbSpinner,
  mdbBadge,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'documentcenterwidget',
  data() {
    return {
      documents: [],
      isloading: true,
    };
  },
  components: {
    mdbTbl,
    mdbTblBody,
    mdbAlert,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbSpinner,
    mdbBadge,
  },
  mounted() {
    this.loadAllDocuments();
  },
  methods: {
    loadAllDocuments() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/document/list').then((response) => {
        self.documents = response.data.documents;
        self.isloading = false;
      });
    },
  },
};
</script>

<style scoped>
tbody tr.showhover:hover{
  background-color: #f5f5f5;
}
tbody tr a:hover{
  text-decoration: underline;
}
</style>
