import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: { canaccess: ['*'] },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'Resetpassword',
    meta: { noguard: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resetpassword" */ '../views/Auth/Resetpassword.vue'),
  },
  {
    path: '/holasun',
    name: 'Holasun',
    meta: { canaccess: ['HOLASUN'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "holasun" */ '../views/Holasun/Calendarview.vue'),
  },
  {
    path: '/holasun/flight/:id',
    name: 'HolasunFlightDetails',
    meta: { canaccess: ['*'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "holasundetails" */ '../views/Holasun/Details.vue'),
  },
  {
    path: '/holasun/booking/:pnr',
    name: 'HolasunBookingDetails',
    meta: { canaccess: ['*'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "holasunbookingdetails" */ '../views/Holasun/BookingDetails.vue'),
  },
  {
    path: '/holasun/stats',
    name: 'HolasunStats',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stats" */ '../views/Holasun/Stats.vue'),
  },
  {
    path: '/holasun/table-view',
    name: 'HolasunTableView',
    meta: { canaccess: ['FULLACCESS', 'HOLASUN'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tableview" */ '../views/Holasun/Tableview.vue'),
  },
  {
    path: '/myaccount',
    name: 'Myaccount',
    meta: { canaccess: ['*'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "myaccount" */ '../views/Other/Myaccount.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userlist" */ '../views/User/List.vue'),
  },
  {
    path: '/users/details/:id',
    name: 'UserDetails',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "userdetail" */ '../views/User/Details.vue'),
  },
  {
    path: '/users/add',
    name: 'UserAdd',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "useradd" */ '../views/User/Add.vue'),
  },
  {
    path: '/flights/sync-with-darwin',
    name: 'FlightsSyncWithDarwin',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "syncflightswithdarwin" */ '../views/Flights/Syncwithdarwin.vue'),
  },
  {
    path: '/flights/list',
    name: 'FlightsList',
    meta: { canaccess: ['FULLACCESS', 'AGENT'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "flightslist" */ '../views/Flights/List.vue'),
  },
  {
    path: '/flights/schedules',
    name: 'Scheduleslist',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "scheduleslist" */ '../views/Flights/SchedulesView.vue'),
  },
  {
    path: '/flights/details/:id',
    name: 'FlightDetails',
    meta: { canaccess: ['FULLACCESS', 'AGENT'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "flightdetais" */ '../views/Flights/Details.vue'),
  }, //
  {
    path: '/flights/report-form/:id',
    name: 'FlightReportForm',
    meta: { canaccess: ['FULLACCESS', 'AGENT'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "flightreport" */ '../views/Flights/ReportInputForm.vue'),
  },
  {
    path: '/flights/manifest-import-log/:id',
    name: 'FlightManifestImportLog',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "flightreport" */ '../views/Flights/ManifestImportLog.vue'),
  },
  //
  {
    path: '/documentation-center',
    name: 'DocumentationCenterBrowse',
    meta: { canaccess: ['FULLACCESS', 'GATEWAYAGENT', 'AGENT'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "documentationcenter" */ '../views/Documents/List.vue'),
  },
  {
    path: '/releases-history',
    name: 'ReleaseHistory',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "releaseshistory" */ '../views/Releaseshistory/Root.vue'),
  },
  {
    path: '/reviews-capture/:id',
    name: 'reviewCaptureApp',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reviewcaptureapp" */ '../views/Flights/ReviewCaptureApp.vue'),
  },
  {
    path: '/nolitrak',
    name: 'NolitrakRoot',
    meta: { canaccess: ['FULLACCESS'], canbypassauth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "nolitrak" */ '../views/Nolitrak/Root.vue'),
  },
  {
    path: '/nolitrak/:callsign',
    name: 'NolitrakDetails',
    meta: { canaccess: ['FULLACCESS'], canbypassauth: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "nolitrak" */ '../views/Nolitrak/Details.vue'),
  },
  {
    path: '/alerts',
    name: 'AlertsList',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts/List.vue'),
  },
  {
    path: '/alerts/add',
    name: 'AddAlert',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts/Add.vue'),
  },
  {
    path: '/alerts/details/:ident',
    name: 'ViewAlert',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts/Edit.vue'),
  },
  {
    path: '/claims',
    name: 'ClaimsList',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "claims" */ '../views/Claims/Root.vue'),
  },
  {
    path: '/qr-code-scanner',
    name: 'QrCodeScanner',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tools" */ '../views/Tools/Qrcodescanner.vue'),
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tools" */ '../views/Blacklist/List.vue'),
  },
  {
    path: '/aircraft/details/:callsign',
    name: 'aircraftdetails',
    meta: { canaccess: ['FULLACCESS'] },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "aircraft" */ '../views/Aircrafts/Details.vue'),
  },
  {
    path: '/trolley-seal-tags',
    name: 'Trolleysealtagsroot',
    meta: { canaccess: ['*'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "trolleysealtags" */ '../views/Trolleysealtags/Root.vue'),
  },
  {
    path: '/notifications',
    name: 'NotificationCenter',
    meta: { canaccess: ['FULLACCESS'] },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notificationcenter" */ '../views/Notifications/Root.vue'),
  },
  {
    path: '/check-in',
    name: 'Checkin',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Root.vue'),
  },
  {
    path: '/enregistrement',
    name: 'Enregistrement',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Root.vue'),
  },
  {
    path: '/check-in/passengers/:token',
    name: 'CheckinPassengers',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Passengers.vue'),
  },
  {
    path: '/enregistrement/passagers/:token',
    name: 'EnregistrementPassagers',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Passengers.vue'),
  },
  {
    path: '/check-in/agreement/:token',
    name: 'CheckinAgreement',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Agreement.vue'),
  },
  {
    path: '/enregistrement/accord/:token',
    name: 'EnregistrementAccord',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Agreement.vue'),
  },
  {
    path: '/check-in/passengers-infos/:token',
    name: 'PassengersInformations',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Paxinfos.vue'),
  },
  {
    path: '/enregistrement/informations-passagers/:token',
    name: 'PassagersInformations',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Paxinfos.vue'),
  },
  {
    path: '/check-in/checked-baggage/:token',
    name: 'PassengersCheckedBaggage',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Checkedbaggage.vue'),
  },
  {
    path: '/enregistrement/bagages-enregistres/:token',
    name: 'PassagersBagagesEnregistres',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Checkedbaggage.vue'),
  },
  {
    path: '/check-in/seat-selection/:token',
    name: 'PassengersSeatSelection',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Seatselection.vue'),
  },
  {
    path: '/enregistrement/selection-siege/:token',
    name: 'PassagersSelectionSieges',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Webcheckin/Seatselection.vue'),
  },
  {
    path: '/alerte/:token',
    name: 'PublicAlerte',
    meta: { noguard: true },
    props: { lang: 'fr' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Alerts/Root.vue'),
  },
  {
    path: '/alert/:token',
    name: 'PublicAlert',
    meta: { noguard: true },
    props: { lang: 'en' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "public" */ '../views/Public/Alerts/Root.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
