import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbvue/lib/css/mdb.min.css';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import Auth from './libs/Auth';
import { ENGLISH_TRANSLATIONS } from './translations/en';
import { FRENCH_TRANSLATIONS } from './translations/fr';

Vue.config.productionTip = false;

Vue.use(VueI18n);

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  fr: FRENCH_TRANSLATIONS,
};

let currentlang = window.localStorage.getItem('currentlang');
if (!currentlang) {
  currentlang = 'fr';
}
store.state.currentlang = currentlang;
window.localStorage.setItem('currentlang', currentlang);

const i18n = new VueI18n({
  locale: store.state.currentlang,
  messages: TRANSLATIONS,
});

router.beforeEach((to, from, next) => {
  let noguard = false;
  let canbypassauth = false;
  if (typeof to.meta !== 'undefined') {
    noguard = to.meta.noguard;
    if (typeof to.meta.canbypassauth !== 'undefined') {
      canbypassauth = to.meta.canbypassauth;
    }
  }
  if (noguard !== true) {
    let mustvalidate = true;
    if (canbypassauth) {
      let cancontinuebypass = false;
      const bypassexp = window.localStorage.getItem('bypassexp');
      if (bypassexp) {
        if (new Date(bypassexp) > new Date()) {
          cancontinuebypass = true;
        }
      }
      if (store.state.bypasscodes.includes(String(to.query.bypass)) || cancontinuebypass) {
        mustvalidate = false;
        store.state.bypassed = true;
        window.localStorage.setItem('bypassexp', new Date(new Date().setDate(new Date().getDate() + 7)).toISOString());
      }
      if (store.state.bypassed) {
        mustvalidate = false;
        next();
      }
    }

    if (mustvalidate) {
      const auth = new Auth();
      auth.isLogged().then((response) => {
        if (response) {
          if (auth.canAccess(to)) {
            next();
          } else {
            next({ name: 'Login', query: { next: to.path } });
          }
        } else {
          next({ name: 'Login', query: { next: to.path } });
        }
      });
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
