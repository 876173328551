<template>
  <div>
    <mdb-navbar class="flexible-navbar white" position="top" double scrolling>
      <mdb-navbar-brand @click.native="toggleSideNav" class="button-collapse"><mdb-icon icon="bars"/></mdb-navbar-brand>
      <mdb-navbar-nav left>
        <mdb-breadcrumb>{{breadcrumb}}</mdb-breadcrumb>
      </mdb-navbar-nav>
      <mdb-navbar-nav class="right-nav sm-very-right" right>

        <notificationheader v-if="$store.state.accessmode === 'FULLACCESS'" />
        <mdb-nav-item v-on:click="opensupportmodal"><mdb-icon icon="comments"/> <div class="d-none d-sm-inline-block">Support</div></mdb-nav-item>

        <mdb-dropdown class="nav-item" tag="li">
          <mdb-dropdown-toggle slot="toggle" tag="li" outline="none" class="btn-transparent">
            <mdb-icon icon="user"/> <span class="clearfix d-none d-sm-inline-block">Profile</span>
          </mdb-dropdown-toggle>
          <mdb-dropdown-menu right>
            <mdb-dropdown-item v-on:click="gotomyaccount">{{$t('menu.myaccount')}}</mdb-dropdown-item>
            <mdb-dropdown-item v-on:click="releaseshistory" v-if="$store.state.accessmode === 'FULLACCESS'">{{$t('menu.releaseshistory')}}</mdb-dropdown-item>
            <mdb-dropdown-item v-on:click="logout">{{$t('menu.logout')}}</mdb-dropdown-item>
          </mdb-dropdown-menu>
        </mdb-dropdown>
      </mdb-navbar-nav>
    </mdb-navbar>
    <mdb-modal :show="showsupportmodal" @close="showsupportmodal=false;supportsuccesssent=false" info>
      <mdb-modal-header class="text-center">
        <mdb-modal-title tag="h4" bold class="w-100">Support</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="mx-3">
        <mdb-alert color="success" v-if="supportsuccesssent">Message Sent!</mdb-alert>
        <mdb-input v-model="support.subject" label="Subject" />
        <mdb-input type="textarea" v-model="support.content" label="Message" :rows="5"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn v-on:click="sendsupport">Send</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
  import {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarNav,
    mdbBreadcrumb,
    mdbDropdown,
    mdbDropdownToggle,
    mdbDropdownMenu,
    mdbDropdownItem,
    mdbIcon,
    mdbNavItem,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    mdbAlert,
    mdbInput,
  }
    from 'mdbvue';
  import Apicall from '@/libs/Apicall';
  import notificationheader from './Notificationheader.vue';

  export default {
    name: 'topnavbar',
    components: {
      mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarNav,
      mdbBreadcrumb,
      mdbDropdown,
      mdbDropdownToggle,
      mdbDropdownMenu,
      mdbDropdownItem,
      mdbNavItem,
      mdbIcon,
      mdbModal,
      mdbModalHeader,
      mdbModalTitle,
      mdbModalBody,
      mdbModalFooter,
      mdbBtn,
      mdbAlert,
      mdbInput,
      notificationheader,
    },
    props: {
      breadcrumb: {
        default: '',
      },
    },
    data() {
      return {
        activePage: this.page,
        toggle: false,
        showsupportmodal: false,
        supportsuccesssent: false,
        support: {
          subject: '',
          content: '',
        },
      };
    },
    methods: {
      gotomyaccount() {
        this.$router.push({ name: 'Myaccount' });
      },
      releaseshistory() {
        this.$router.push({ name: 'ReleaseHistory' });
      },
      opensupportmodal() {
        this.support.subject = '';
        this.support.content = '';
        this.showsupportmodal = true;
      },
      sendsupport() {
        const self = this;
        const api = new Apicall();
        api.call('POST', 'api/me/support', this.support).then(() => {
          this.support.subject = '';
          this.support.content = '';
          this.supportsuccesssent = true;
        });
      },
      logout() {
        window.localStorage.removeItem('token');
        this.$router.push({ name: 'Login' });
      },
      toggleSideNav() {
        if (this.$store.state.sidenavtoggle) {
          this.$store.state.sidenavtoggle = false;
        } else {
          this.$store.state.sidenavtoggle = true;
        }
      },
    },
    computed: {
      computedPage() {
        return {
          activePage: this.page,
        };
      },
    },
  };
</script>

<style scoped>

.navbar .nav-item {
  line-height: 24px;
  font-size: 16px;
}
.navbar .dropdown-toggle {
  padding: 0 10px;
  font-size: 16px;
}

.navbar ul {
  padding: 5px 0;
}

.navbar .btn {
  font-weight: 300;
}

.navbar-collapse {
  order: 0 !important;
}

.navbar .notifications-nav .dropdown-menu {
  width: 23rem; }

.navbar-expand-lg .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.btn-transparent {
  background-color: transparent !important;
  color: #000;
  text-transform: none;
  box-shadow: none !important;
}

.btn-transparent:hover, .btn-transparent:active {
  box-shadow: none !important;
}

@media (max-width: 575px) {
  .sm-very-right {
    margin-right: -65px;
  }
}

.notif-badge{
  margin-left: -0.5rem;
  padding: 0.2em 0.45em;
}

</style>
